import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const CloseIcon: React.FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '14px',
    height = '14px',
    fill = 'black',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="close-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 14 14"
            height="100%"
            fill={fill}
            display="block"
            {...props}
        >
            <path d="M1.81002 3.03892C1.47088 2.69977 1.47088 2.1499 1.81002 1.81076C2.14917 1.47161 2.69904 1.47161 3.03818 1.81076L7.00011 5.77268L10.962 1.81076C11.3012 1.47161 11.851 1.47161 12.1902 1.81076C12.5293 2.1499 12.5293 2.69977 12.1902 3.03892L8.22827 7.00084L12.1902 10.9628C12.5293 11.3019 12.5293 11.8518 12.1902 12.1909C11.851 12.5301 11.3012 12.5301 10.962 12.1909L7.00011 8.229L3.03818 12.1909C2.69904 12.5301 2.14917 12.5301 1.81002 12.1909C1.47088 11.8518 1.47088 11.3019 1.81002 10.9628L5.77195 7.00084L1.81002 3.03892Z" />
        </svg>
    </SVGWrapper>
)

export default CloseIcon
