import * as React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const GuaranteeIcon: React.FC<CustomIconProps> = ({ width = 'auto', height = '27px', ...props }) => (
    <SVGWrapper width={width} height={height}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            fill="none"
            data-testid="guarantee-icon"
            {...props}
        >
            <path
                fill="#13BDC3"
                fillRule="evenodd"
                d="M3.299 2.746A25.454 25.454 0 0 1 13.469.724a25.494 25.494 0 0 1 10.203 2.028c.923.412 1.512 1.312 1.506 2.294v8.18c0 5.67-3.588 10.755-9.026 12.791l-1.149.43a4.357 4.357 0 0 1-3.05 0l-1.15-.43c-5.437-2.036-9.025-7.12-9.025-12.791V5.048c-.006-.986.584-1.885 1.52-2.302Zm.429 2.39v8.095c0 4.86 3.086 9.22 7.764 10.964l1.14.425a2.425 2.425 0 0 0 1.692 0l1.14-.425c4.677-1.745 7.764-6.103 7.764-10.964V5.136a.67.67 0 0 0-.393-.614 23.477 23.477 0 0 0-9.366-1.848 23.437 23.437 0 0 0-9.334 1.842.676.676 0 0 0-.407.62Zm10.018 13.296 5.985-7.97h-.004c.27-.348.326-.811.148-1.211a1.237 1.237 0 0 0-1.008-.722 1.252 1.252 0 0 0-1.14.502l-4.958 6.604a.252.252 0 0 1-.376.028l-3.314-3.236a1.261 1.261 0 0 0-1.738.015 1.19 1.19 0 0 0-.015 1.696l4.54 4.43c.231.227.547.355.876.355h.1c.36-.027.69-.207.904-.491Z"
                clipRule="evenodd"
            />
        </svg>
    </SVGWrapper>
)
export default GuaranteeIcon
