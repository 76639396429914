import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'
import classnames from 'classnames'

import styles from './styles.module.scss'

type ArrowDirection = 'up' | 'down' | 'left' | 'right'
export type ArrowIconProps = CustomIconProps & { direction?: ArrowDirection }

const ArrowIcon: React.FC<ArrowIconProps> = ({
    direction = 'left',
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper
        className={classnames(className, {
            [styles.up]: direction === 'up',
            [styles.down]: direction === 'down',
            [styles.right]: direction === 'right',
        })}
        fontSize={fontSize}
        width={width}
        height={height}
        data-testid="arrow-icon-wrapper"
    >
        <svg
            data-testid="arrow-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="100%"
            height="100%"
            fill="none"
            {...props}
        >
            <g mask="url(#a)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0278 9.92504L9.27973 6.50236C8.9175 6.17159 8.9175 5.62853 9.27973 5.29775C9.63349 4.97471 10.2016 4.97471 10.5553 5.29775L15.9506 10.2245C16.3128 10.5553 16.3128 11.0983 15.9506 11.4291L10.5553 16.3559C10.2016 16.6789 9.63349 16.6789 9.27973 16.3559C8.9175 16.0251 8.9175 15.482 9.27973 15.1513L13.0278 11.7286L4.46502 11.7286C4.08547 11.7286 3.77778 11.3248 3.77778 10.8268C3.77778 10.3288 4.08547 9.92504 4.46502 9.92504L13.0278 9.92504Z"
                    fill="#04092C"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default ArrowIcon
