import * as React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const LogoIcon: React.FC<CustomIconProps> = ({ width = 'auto', height = '27px', ...props }) => (
    <SVGWrapper width={width} height={height}>
        <svg xmlns="http://www.w3.org/2000/svg" width={27} height={26} fill="none" data-testid="logo-icon" {...props}>
            <path fill="#0983B6" d="M17.79 16.81 4.982 8.918H22.35l-4.56 7.892Z" />
            <path fill="#0951B6" d="M26.666 1.445 4.982 8.918H22.35l4.317-7.473Z" />
            <path fill="#13BDC3" d="m4.987 8.928-.006-.01 12.808 7.893-4.125 7.141L4.987 8.928Z" />
            <path fill="#CE3197" d="M26.666 1.445 4.985 8.917.667 1.447l26-.002Z" />
        </svg>
    </SVGWrapper>
)
export default LogoIcon
