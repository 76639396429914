import * as React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const TrustPilotIcon: React.FC<CustomIconProps> = ({ width = 'auto', height = '32px', ...props }) => (
    <SVGWrapper width={width} height={height}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="none"
            data-testid="trustpilot-icon"
            {...props}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#00B67A"
                    d="M31.997 12.409H19.779L16.006.762 12.22 12.409.003 12.397l9.894 7.205-3.786 11.636 9.895-7.194 9.882 7.194-3.774-11.636 9.883-7.193Z"
                />
                <path fill="#005128" d="m22.963 22.236-.849-2.634-6.108 4.441 6.957-1.807Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 .762h32v30.476H0z" />
                </clipPath>
            </defs>
        </svg>
    </SVGWrapper>
)
export default TrustPilotIcon
